
<template>
    <div class="Marketplace">
        <div class="tabs">
            <div class="center connectBoxW">
                <div @click="tabIndex = 0;getData(0)">
                    <div :class=" tabIndex == 0 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 0 ? '' : 'no'">Common Planet</p>
                </div>
                <div @click="tabIndex = 1;getData(1)">
                    <div :class=" tabIndex == 1 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 1 ? '' : 'no'">Unique Planet</p>
                </div>
                <div @click="tabIndex = 2;getData(2)">
                    <div :class=" tabIndex == 2 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 2 ? '' : 'no'">Exclusive Star Sign</p>
                </div>
            </div>
        </div>
        <div class="nftList">
            <div v-if="tabIndex == 0" class="center connectBoxW">
                <div class="nodata" v-if="level0list.length == 0">
                    <img class="nodataImg" v-if="!dataLoading" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="!dataLoading" style="color:#feffbc;">No data yet</p>
                    <p v-if="dataLoading" style="color:#feffbc;">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div>
                <ul :class="level0list.length <= 3 ? 'li3' : ''">
                    <li v-for="(item,index) in level0list" :key="index">
                        <div class="nftcard" @click="Details(item)">
                            <div class="nftimg">
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="tabIndex == 1" class="center connectBoxW">
                <div class="nodata" v-if="level1list.length == 0">
                    <img class="nodataImg" v-if="!dataLoading" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="!dataLoading" style="color:#feffbc;">No data yet</p>
                    <p v-if="dataLoading" style="color:#feffbc;">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div>
                <ul :class="level1list.length <= 3 ? 'li3' : ''">
                    <li v-for="(item,index) in level1list" :key="index">
                        <div class="nftcard" @click="Details(item)">
                            <div class="nftimg">
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="tabIndex == 2" class="center connectBoxW">
                <div class="nodata" v-if="level2list.length == 0">
                    <img class="nodataImg" v-if="!dataLoading" src="../assets/logos/spacefiFFF.png" alt="">
                    <p v-if="!dataLoading" style="color:#feffbc;">No data yet</p>
                    <p v-if="dataLoading" style="color:#feffbc;">
                        <v-LoadingWarp style="margin-right:10px;" :imgSize="3"></v-LoadingWarp> Loading . . .
                    </p>
                </div>
                <ul :class="level2list.length <= 3 ? 'li3' : ''">
                    <li v-for="(item,index) in level2list" :key="index">
                        <div class="nftcard" @click="Details(item)">
                            <div class="nftimg">
                                <el-image :src="item.nftUrl" alt="Failed to load" lazy>
                                    <div slot="placeholder" class="image-slot">
                                        loading<span class="dot"> <i class="el-icon-loading"></i></span>
                                    </div>
                                    <div slot="error" class="image-slot" style="color:#fff;">
                                        <i class="el-icon-picture-outline"></i> Failed to load
                                    </div>
                                </el-image>
                            </div>
                            <div class="nftinfo">
                                <p class="name">{{item.name}} <span>{{item.multiple}}X</span></p>
                                <p class="assets">NFT Assets: <span>{{item.assets}} {{tokenSymbol}}</span></p>
                                <p class="price">Price: <span>{{item.price}} {{tokenSymbol}}</span></p>
                            </div>
                        </div>
                        <div class="details" @click="Details(item)">
                            <p>Details</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        <v-Footer></v-Footer>
    </div>
</template>

<script>
import {
    getGroupTokensLength,groupTokens,marketTokenInfo,starMeta,cateURI
} from '../utils/space.js'
export default {
    name: "Marketplace",
    data() {
        return {
            dataLoading:true,
            userAddress:'',tokenSymbol:'',xtokenSymbol:'',tokenAddress:'',xtokenAddress:'',nftAddress:'',
            tabIndex: 0,mintToken:0,
            page:1,size:12,
            level0list:[],
            level1list:[],
            level2list:[],
        }
    },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.nftInfoList = window.nftImgData.cardList
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.nftAddress = window.contractAddr.nftAddress
        this.logicalAddress = window.contractAddr.logicalAddress
        this.marketAddress = window.contractAddr.marketAddress
        this.getData(0)
    },
    methods: {
        Details(item){
            this.$router.push({
                path:'/nftDetail',
                query:{
                    tokenid:item.NFTId,
                    type:1
                }
            })
        },
        getData(level){
            this.dataLoading = true
            this.level0list = []
            this.level1list = []
            this.level2list = []
            getGroupTokensLength(this.marketAddress,level,this.userAddress).then(marketNum => {
                if (marketNum == 0) {
                    this.dataLoading = false
                }
                for (let i = 0; i < marketNum; i++) {
                    groupTokens(this.marketAddress,level,i,this.userAddress).then(NFTId => {
                        //console.log(NFTId)
                        marketTokenInfo(this.marketAddress,NFTId,this.userAddress).then(userNftPrice => {
                            //console.log(userNftPrice)
                            starMeta(this.logicalAddress,NFTId).then(nftInfo => {
                                cateURI(this.nftAddress,NFTId).then(nftUrl => {
                                    var obj = {
                                        nftUrl,
                                        cateId:nftInfo.cateId,
                                        NFTId:Number(NFTId),
                                        name:'',
                                        multiple:Number(nftInfo.multiple/100).toFixed(2),
                                        assets:Number((nftInfo.price/Math.pow(10,18))*(nftInfo.multiple/100)).toFixed(0),
                                        price:Number((userNftPrice.price/Math.pow(10,18))),
                                    }
                                    this.nftInfoList.forEach((item,index) => {
                                        if (item.cid == nftUrl.split('/')[3]) {
                                            obj.name = item.name
                                        }
                                    })
                                    if (nftInfo.level == 0) {
                                        this.level0list.push(obj)
                                        let arr0 = this.level0list
                                        this.dataSort(arr0)
                                    }
                                    if (nftInfo.level == 1) {
                                        this.level1list.push(obj)
                                        let arr1 =  this.level1list
                                        this.dataSort(arr1)
                                    }
                                    if (nftInfo.level == 2) {
                                        this.level2list.push(obj)
                                        let arr2 =  this.level2list
                                        this.dataSort(arr2)
                                    }
                                    //console.log(this.level0list)
                                })
                            })
                        })
                    
                    })
                }
            })
        },
        dataSort(arr){
            arr.sort((a ,b) => {
                if (a.NFTId > b.NFTId) {
                    return -1
                } else if (a.NFTId < b.NFTId) {
                    return 1
                }
            })
        },
    }
}
</script>